import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import SliceZone from '../components/SliceZone'
import SEO from '../components/SEO/seo'


const DealersPage = ({ data }) => {
  if (!data) return null

  const doc = data.prismic.allDealers_pages.edges[0]
  const slices = doc.node.body

  return (
    <Root>
      <SEO title='Dealers' />
      <SliceZone slices={slices} />
    </Root>
  )
}

const Root = styled.div`
 & a {
   font-family: 'Futura Bold';
 }
 & p {
   text-align: center;
 }
`;

export const query = graphql`
query DealersPageQuery($lang: String) {
  prismic {
    allDealers_pages(lang: $lang) {
      edges {
        node {
          _meta {
            lang
            type
            uid
            alternateLanguages {
              lang
              type
              uid
            }
          }
          body {
            ... on PRISMIC_Dealers_pageBodyCentered_image {
              type
              label
              primary {
                image
              }
            }
            ... on PRISMIC_Dealers_pageBodyImage_with_text {
              type
              label
              primary {
                image
                text
              }
            }
          }
        }
      }
    }
  }
}


`;
export default DealersPage